@import "./theme";

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.donations-container {
  display: flex;
  justify-content: center;
}

.test-donation-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: $dangerColor;
  color: $light;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 10px;
  z-index: 10;
}
.donations-card-container {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
}

.right-panel-container {
  display: flex;
  padding: 20px;
  width: 100%;
  padding-bottom: 30px;
  max-width: 360px;
  min-height: 560px;
  align-self: center;
  background-color: $backgroundColor;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px -3px rgba($color: #000000, $alpha: 0.16);
}

.scroll-donation-forms {
  height: 560px;
  overflow-y: scroll;

  //firefox
  scrollbar-width: thin;
  scrollbar-color: $darkGreyColor $dividerColor;

  //edit scroll bar for webkit browsers:  Safari & Chrome
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $dividerColor;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $darkGreyColor;
    border-radius: 20px;
  }
}

.donations-transaction-details {
  font-size: 12px;
  color: $light;
  z-index: 3;
  padding-bottom: 10px;
  text-align: left;
  &.donation-transaction-phone-view {
    text-align: center;
    color: black;
  }
}

.user-profile {
  display: flex;
  align-items: center;
  text-decoration: none;
  p {
    color: $primaryFontColor !important;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .profile-name {
    color: $primaryFontColor !important;
  }
  .profile-pic {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin-right: 6px;
  }
  .no-pic {
    background-color: #b2b2b2;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.login-continue {
  color: $primaryColor;
  font-weight: 600;
  text-align: right;
}

.planet-cash-selector {
  margin-top: 10px;

  .planet-cash-selector-toggle {
    display: flex;
    justify-content: space-between;

    .planet-cash-balance-positive {
      color: $primaryColor;
      font-weight: bold;
    }

    .planet-cash-balance-negative {
      color: $dangerColor;
      font-weight: bold;
    }

    .add-plant-cash-balance {
      font-size: 12px;
    }
  }
}

.on-behalf-container {
  .on-behalf-container-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .on-behalf-container-form-container {
    .form-errors {
      color: $dangerColor;
    }
  }
}

.on-behalf-donor-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .remove-on-behalf-donor {
    cursor: pointer;
    color: $dangerColor;
    font-size: 12px;
    font-weight: 600;
  }
}

.donations-gift-container {
  .donations-gift-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > label {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .donation-supports-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      cursor: pointer;
    }
    button {
      display: flex;
      align-items: center;
    }
    svg {
      > path {
        fill: $dangerColor;
      }
    }
  }
}

.funds-frequency-container {
  width: 70%;
  display: block;
  margin: auto;
}
.currency-selection {
  display: flex;
  align-items: center;
  > button {
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.tax-country-selection {
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.tree-selection-options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tree-selection-option {
  width: 90px; // Width 100px supported from
  height: 54px;
  border: 1px solid $greyColor;
  border-radius: 4px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  &.tree-selection-option-selected,
  &:hover,
  &:focus {
    cursor: pointer;
    border: 1px solid $primaryColor;
    > svg {
      filter: grayscale(0%) opacity(1);
    }
    .tree-selection-option-text {
      color: $primaryFontColor;
      p {
        color: $primaryFontColor;
      }
    }
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.98);
  }
  > svg {
    filter: grayscale(100%) opacity(0.5);
  }
  .tree-selection-option-text {
    text-align: center;
    line-height: 1;
    margin-left: 10px;
    color: $darkGreyColor;
    > p {
      font-size: 14px;
      font-weight: 800;
      color: $darkGreyColor;
    }
    > span {
      font-size: 12px;
    }
  }
  .custom-tree-option {
    display: flex;
    flex-direction: row;
    > span {
      align-self: center;
      padding-left: 5px;
    }
  }
}

.bouquet-project-space-divider {
  width: 10px;
  @include smTabletView {
    width: 30px;
  }
  @media screen and (max-width: 341px) {
    width: 0px;
  }
}
.funding-selection-options-container,
.bouquet-selection-options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  .funding-selection-option,
  .bouquet-selection-option {
    width: 130px;
    margin: 10px;
    // height: 80px;
    border: 1px solid $greyColor;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
    background-color: $secondaryBackgroundColor;
    justify-content: center;
    text-align: center;
    &.custom {
      align-items: stretch;
      p {
        color: $activeGreenBgTextColor;
      }
    }
    &.flex-50 {
      flex: calc(50% - 20px);
    }
    &.funding-selection-option-selected,
    &.bouquet-selection-option-selected,
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $primaryColor;
      border: 1px solid $primaryColor;
      .funding-selection-option-text,
      .bouquet-selection-option-text {
        color: $primaryFontColor;
        p,
        span {
          color: $activeGreenBgTextColor;
        }
      }
      .funding-icon {
        svg {
          color: $activeGreenBgTextColor;
        }
      }
    }
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.98);
    }
    .funding-selection-option-text,
    .bouquet-selection-option-text {
      text-align: left;
      line-height: 1;
      // margin-left: 12px;
      color: $secondaryFontColor;
      // color: $darkGreyColor;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      p {
        color: $secondaryFontColor;
        // color: $darkGreyColor;
        margin-bottom: 10px;
      }
      > span {
        margin-top: 10px;
      }
    }
    .funding-icon {
      svg {
        width: 34px;
        color: $secondaryFontColor;
      }
    }
  }
}

.frequency-selection-container {
  display: flex;
  flex-direction: row;
  background-color: $backgroundColorDark;
  height: 50px;
  border-radius: 25px;
  padding: 8px;
}

.frequency-selection-option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  border-radius: 25px;
  color: $primaryFontColor;
  font-weight: 600;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
  }
}
.frequency-selection-option-selected {
  background-color: $primaryColor;
  color: $light;
}
.custom-selection {
  flex-grow: 1;
  margin-left: 25px;
}

.suggestions-container {
  position: absolute;
  z-index: 10;
  background-color: $backgroundColorDark;
  max-height: 200px;
  overflow: scroll;
  box-shadow: 0px 3px 6px -3px rgba($color: #000000, $alpha: 0.26);
  min-width: 100%;
  .suggestion {
    min-width: 100%;
    &:nth-child(even) {
      background-color: rgba(var(--background-color-dark), 0.6);
    }
    padding: 12px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &:hover {
      cursor: pointer;
      background-color: $backgroundColorDark;
    }
  }
}

.contacts-isCompany-toggle {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  > label {
    &:hover {
      cursor: pointer;
    }
    > span {
      display: block;
    }
  }
}

.welcome-package-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $primaryColor;
  border-radius: 10px;
  padding: 10px;
  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:hover {
      cursor: pointer;
    }
    svg {
      width: 40px;
      height: auto;
    }
  }
}

.custom-tree-input {
  border: 0px;
  border-bottom: 2px solid $greyColor;
  width: 100%;
  font-family: $primaryFontFamily;
  font-weight: 800;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  text-align: center;
  background-color: transparent;
  outline: 0px;
  font-size: 16px;
}
.funding-custom-tree-input,
.bouquet-custom-tree-input {
  border: 0px;
  border-bottom: 2px solid $activeGreenBgTextColor;
  width: 100%;
  font-family: $primaryFontFamily;
  font-weight: 800;
  font-size: $fontSubTitleSize;
  color: $activeGreenBgTextColor;
  text-align: center;
  background-color: transparent;
  outline: 0px;
  font-size: 16px;
}

.tree-selection-option > .custom-tree-input:focus {
  display: block;
  outline: none;
}

.tree-selection-option-selected .custom-tree-input {
  border-bottom: 2px solid $primaryColor;
}

.payment-methods-tabs-container {
  display: flex;
  padding-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  > .payment-method {
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
    border: 1px solid $dividerColor;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    height: 36px;
    color: $dividerColor;
    justify-content: center;
    position: relative;
    background-color: $light;
    &.bank {
      width: auto;
      display: flex;
      align-items: flex-start;
      align-self: center;
      justify-content: center;
      padding: 4px 8px;
      text {
        font-weight: bold;
        font-style: italic;
        color: #4d5153;
        margin-left: 8px;
      }
    }
    > label {
      margin-top: 6px;
      font-size: 10px;
    }
    &:hover {
      cursor: pointer;
      color: $primaryFontColor;
      background-color: #f2f2f7;
      border: 0.5px solid #f2f2f7;
    }
    .check-mark {
      display: none;
    }
  }
  .payment-method-selected {
    background-color: #f2f2f7;
    border: 0.5px solid #f2f2f7;
    color: $primaryFontColor;
    font-weight: 600;

    > .check-mark {
      display: block;
      position: absolute;
      right: -6px;
      top: -6px;
    }
  }
}

.disclaimer-container {
  display: flex;
  flex-direction: row;
  background-color: $backgroundColorDark;
  border-radius: 10px;
  width: 100%;
  padding: 14px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  > svg {
    width: 48px;
    height: 20px;
    fill: $primaryFontColor;
  }
  > p {
    margin-left: 8px;
  }
}
.transfer-details {
  display: flex;
  flex-direction: column;
  background-color: $backgroundColorDark;
  border-radius: 10px;
  width: 100%;
  padding: 4px 20px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0px;
  > .single-detail {
    border: 1px solid #e2e2e2;
    border-width: 0 0 1px 0;
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    &:last-of-type {
      border: 0;
    }
    > p {
      font-weight: bold;
    }
    > .value-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > .detail-value {
        color: #48aadd;
      }
    }
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
.mandate-acceptance {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
}

.horizontal-line {
  height: 1px;
  background-color: $greyColor;
  margin-top: 20px;
}

.thankyou-image {
  background-image: url("/assets/images/donationCompletion.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 280px;
  height: 280px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.donation-count {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: $light;
  font-variant-ligatures: none;
}

.donation-url {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  margin-top: 16px;
  color: $light;
  font-variant-ligatures: none;
  margin-bottom: 40px;
}

.thankyou-image-header {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: $light;
  font-variant-ligatures: none;
  padding-top: 15%;
  white-space: pre-line;
  flex-grow: 1;
}

.thankyou-image-header p h1 {
  font-size: 34px;
  font-weight: bolder;
}

.temp-image {
  height: 0px;
  width: 0px;
  overflow: hidden;
  .thankyou-image {
    border-radius: 0px;
    margin: 0px;
    width: 2300px;
    height: 2300px;
  }
  .donation-count {
    font-size: 130px;
    padding: 0px 80px;
    margin-bottom: 200px;
  }
  .donation-url {
    font-size: 75px;
    margin: 0px;
    margin-bottom: 100px;
  }
  .thankyou-image-header {
    font-size: 100px;
    width: 100%;
    padding-top: 17%;
  }
  .thankyou-image-header p h1 {
    font-size: 150px;
  }
}

.share-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -30px;
  width: fit-content;
  left: 50%;
  border-radius: 30px;
  height: 36px;
  width: 200px;
  padding-left: 24px;
  padding-right: 24px;
  transform: translate(-50%, 0%);
  background-color: $light;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  svg {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include smTabletView {
  .right-panel-container {
    max-width: 420px;
    padding: 30px;
  }
  .tree-selection-option {
    width: 100px;
    padding: 12px;
  }
  .funding-selection-options-container,
  .bouquet-selection-options-container {
    margin-top: 10px;
    .funding-selection-option,
    .bouquet-selection-option {
      width: 160px;
      &.monthly-option {
        width: 100px;
      }
      &.full-width {
        width: 100%;
      }
      &.flex-50 {
        flex: calc(50% - 20px);
      }
    }
  }
  .custom-selection {
    margin-left: 30px;
  }
  .donations-container {
    padding-top: 80px;
  }
  .thankyou-image {
    width: 360px;
    height: 360px;
  }
  .donation-count {
    font-size: 22px;
  }
}
.thankyouText {
  text-align: center;
  color: $primaryFontColor;
}
.linkText {
  text-align: center;
  color: $primaryFontColor;
  &:hover {
    text-decoration: underline;
  }
}
@include lgLaptopView {
  .thankyouText {
    text-align: left;
  }
  .test-donation-bar {
    top: 0px;
    bottom: auto;
  }
  .donations-card-container {
    width: 840px;
    flex-direction: row;
    border-radius: 20px;
    min-height: 560px;
    align-items: center;
    max-width: 840px;
  }
  .right-panel-container {
    width: 420px;
    padding-bottom: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
    align-self: auto;
    margin-left: -10px;
    z-index: 3;
  }
  .thankyou-image {
    width: 360px;
    height: 360px;
  }
}

.donations-info-loader {
  width: 100%;
  height: 100%;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

@keyframes loader {
  0% {
    background-color: rgba(#ecebeb, 0.3);
  }
  50% {
    background-color: rgba(#f3f3f3, 0.5);
  }
  100% {
    background-color: rgba(#ecebeb, 0.3);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(#ecebeb, 0.3);
  }
  50% {
    background-color: rgba(#f3f3f3, 0.5);
  }
  100% {
    background-color: rgba(#ecebeb, 0.3);
  }
}

.dark-pay {
  background-image: none;
  background-color: #000;
  svg {
    margin-left: 4px;
    height: 20px;
    width: 47.15px;
  }
}
.donate-now {
  svg {
    height: 20px;
    width: 47.15px;
  }
  background-image: linear-gradient(103deg, #383461 5%, #684889 116%);
}
.donate-small {
  svg {
    margin-left: 4px;
    height: 20px;
    width: 47.15px;
  }
  box-sizing: border-box;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid $dividerColor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
  height: 36px;
  color: $dividerColor;
  justify-content: center;
  position: relative;
  > label {
    margin-top: 6px;
    font-size: 10px;
  }
  &:hover {
    cursor: pointer;
    color: $primaryFontColor;
    background-color: $backgroundColorDark;
    border: 0.5px solid $backgroundColorDark;
  }
}

.mailing-buttons {
  a {
    margin-left: 12px;
    margin-right: 12px;
    svg {
      height: 30px;
      filter: grayscale(100%);
      &:hover {
        cursor: pointer;
        filter: grayscale(0%);
      }
    }
  }
}

.ElementsApp .Icon-fill {
  fill: $primaryFontColor;
}

.copy-container {
  position: relative;
  & .copy-tooltip {
    position: absolute;
    bottom: 36px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 6px;
    width: 100px;
    left: -40px;
    background-color: $darkGreyColor;
    color: $dark;
    transition: all 0.3s;
  }
  &:hover .copy-tooltip,
  & .copy-tooltip.show-tooltip {
    display: flex;
  }
}

.thank-you-purpose {
  text-align: center;
  .go-back {
    margin-bottom: 300px;
  }
}

@include lgLaptopView {
  .thank-you-purpose {
    text-align: left;
  }
}
